import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'

import classNames from 'classnames'

import Thumbnail from '../thumbnail/thumbnail'

import styles from "./nav.module.scss"

function Nav({ className, format, variant, ...rest }) {
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={ data => (
        <Thumbnail
          className={ classNames(styles.nav, styles[format], className) }
          hoverClassName="h2"
          hoverTag="h2"
          hoverText={ data.site.siteMetadata.description }
          path="/"
          previewTag="h1"
          tag="nav"
          text={ data.site.siteMetadata.title }
          textClassName="h1"
          variant={ variant }
          { ...rest }
        />
      ) }
      { ...rest }
    />
  )
}

Nav.propTypes = {
  className: PropTypes.string,
  format: PropTypes.oneOf(['default', 'single']),
  variant: PropTypes.oneOf(['light', 'dark']),
}

Nav.defaultProps = {
  className: null,
  format: 'default',
  variant: null,
}

export default Nav
