import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PoemHeader from '../poemHeader/poemHeader'

import styles from "./thumbnail.module.scss"

import Link from 'gatsby-link'

function Thumbnail({
  className, date, fontSize, hoverClassName, hoverTag, hoverText, hoverFontSize, path, previewTag, tag: Tag, text, textClassName, variant, ...rest
}) {
  return (
    <Tag className={ classNames(styles.thumbnail, styles[variant], className) } { ...rest }>
      <Link to={ path }>
        <PoemHeader
          className="preview"
          title={ text }
          titleClassName={ textClassName }
          titleFontSize={ fontSize }
          titleTag={ previewTag }
        />
        <PoemHeader
          className="hover"
          date={ date }
          title={ hoverText }
          titleClassName={ hoverClassName }
          titleFontSize={ hoverFontSize }
          titleTag={ hoverTag }
        />
      </Link>
    </Tag>
  )
}

Thumbnail.propTypes = {
  className: PropTypes.string,
  date: PropTypes.string,
  fontSize: PropTypes.number,
  hoverClassName: PropTypes.oneOf(['h1', 'h2']),
  hoverTag: PropTypes.oneOf(['h1', 'h2']),
  hoverText: PropTypes.string,
  hoverFontSize: PropTypes.number,
  path: PropTypes.string.isRequired,
  previewTag: PropTypes.oneOf(['h1', 'h2']),
  text: PropTypes.string.isRequired,
  textClassName: PropTypes.oneOf(['h1', 'h2']),
  tag: PropTypes.string,
  variant: PropTypes.oneOf(['light', 'dark']),
}

Thumbnail.defaultProps = {
  className: null,
  date: null,
  fontSize: null,
  hoverClassName: 'h1',
  hoverTag: 'h1',
  hoverText: '{ Untitled }',
  hoverFontSize: null,
  previewTag: 'h2',
  tag: 'article',
  textClassName: 'h2',
  variant: null,
}

export default Thumbnail
